import * as Turbo from '@hotwired/turbo'
import '../style.sass'
import * as bootstrap from 'bootstrap'
import "@fortawesome/fontawesome-free/js/all"

// importa i controller di stimulus
import { Application } from "@hotwired/stimulus"
// esempio: import SliderHomePageController from "../controllers/slider_home_page_controller"
window.Stimulus = Application.start()
// esempio: Stimulus.register("slider-home-page", SliderHomePageController)

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import Swiper JS
import Swiper from 'swiper';
import { Autoplay, EffectFade  } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

Turbo.start()

// tutti i nodi .swiper vengono inizializzati con Swiper
function setupSwiper() {
  const elements = document.querySelectorAll('.swiper');
  elements.forEach(element => {
    new Swiper(element, {
      modules: [Autoplay, EffectFade],
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      speed: 3000,
      autoplay: {
        delay: 7000,
      },
    });

  });
}

function setupCkEditor(){
  var elements = document.querySelectorAll('.ckeditor');
  elements.forEach(element => {
    ClassicEditor
      .create(element, {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        }
      })
      .then(editor => {
        // Handle the form submit event to update CKEditor data
        const form = element.closest('form');
        form.addEventListener('submit', () => {
          element.value = editor.getData();
        });
      })
      .catch(error => {
        console.error(error);
      });
  });
}

document.addEventListener("turbo:load", function() {
  setupSwiper();
});

document.addEventListener("turbo:frame-load", function() {
  setupCkEditor();
});